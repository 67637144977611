import React from 'react';

const DanceCard = ({ dance_id, dance_image, dance_title, dance_tags }) => {
    return (
      <div className="aspect-w-2 aspect-h-1 border border-gray-300">
        <div className="flex flex-col">
          <img
            src={dance_image}
            alt={dance_title}
            className="w-full h-2/3 object-cover"
          />
          <h3 className="text-center text-lg font-bold h-1/6">{dance_title}</h3>
          <div className="text-center text-sm h-1/6">
            {dance_tags.map((tag, index) => (
              <span key={`${dance_id}-${index}`} className="mx-1">
                #{tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default DanceCard;
