import React from 'react';

const IconItem = ({ icon_src, icon_alt, icon_name }) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src={icon_src}
        alt={icon_alt}
        className="w-12 h-12 object-cover mx-5"  // Adjusts image size to 100x100px
      />
      <p className="text-center mt-2">{icon_name}</p>
    </div>
  );
};

export default IconItem;
