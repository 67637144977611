import React from 'react';

const FeaturedDanceImage = ({ featured_src, featured_alt }) => {
    return (
        <img
            className="max-w-xxs max-h-48 object-cover rounded-t-lg"
            src={featured_src}
            alt={featured_alt}
        />
    );
};

export default FeaturedDanceImage;
