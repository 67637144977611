import React from 'react';
import FeaturedDanceCard from './FeaturedDanceCard';

const FeaturedDanceSection = ({ featured_dances }) => {
    return (
        <div className="flex justify-center items-center flex-wrap max-w-screen-lg mx-auto p-4">
            {featured_dances.map((featured_dance, index) => (
                <div className="w-1/3 px-2">
                <FeaturedDanceCard
                    key={index}
                    featured_src={featured_dance.featured_src}
                    featured_alt={featured_dance.featured_alt}
                    featured_title={featured_dance.featured_title}
                />
                </div>
            ))}
        </div>
    );
};

export default FeaturedDanceSection;
