import React from 'react';

const Logo = () => {
  return (
    <div className="text-black text-2xl font-bold">
      Learn Dance
    </div>
  );
};

export default Logo;