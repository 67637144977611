import React from 'react';
import CoverSection from './CoverSection';
import ContentGrid from './ContentGrid';
import IconStrip from './IconStrip';

// Dummy data for FeaturedDanceSection
const FeaturedDancesDummy = [
  { featured_src: 'image.png', featured_alt: 'Dance 1', featured_title: 'Dance 1' },
  { featured_src: 'image.png', featured_alt: 'Dance 2', featured_title: 'Dance 2' },
  { featured_src: 'image.png', featured_alt: 'Dance 3', featured_title: 'Dance 3' }
];

// Dummy data for ContentGrid
const DancesDummy = [
  { dance_id: '1', dance_image: 'female_latin.png', dance_title: 'Dance 4', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '2', dance_image: 'image.png', dance_title: 'Dance 5', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '3', dance_image: 'female_latin.png', dance_title: 'Dance 6', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '4', dance_image: 'female_latin.png', dance_title: 'Dance 7', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '5', dance_image: 'image.png', dance_title: 'Dance 4', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '6', dance_image: 'image.png', dance_title: 'Dance 5', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '7', dance_image: 'female_latin.png', dance_title: 'Dance 6', dance_tags: ['tag1', 'tag2'] },
  { dance_id: '8', dance_image: 'image.png', dance_title: 'Dance 7', dance_tags: ['tag1', 'tag2'] },
];

const IconsDummy = [
  { icon_src: 'image.png', icon_alt: 'Group Icon', icon_name: 'Group' },
  { icon_src: 'image.png', icon_alt: 'Duo Icon', icon_name: 'Duo' },
  { icon_src: 'image.png', icon_alt: 'Male Icon', icon_name: 'Male' },
  { icon_src: 'image.png', icon_alt: 'Female Icon', icon_name: 'Female' },
];

const HomePage = () => {
  return (
    <div className="min-h-screen">
      <div>
        <CoverSection 
          hero_title="LearnDance" 
          hero_description="Get grooving in just 5 minutes"
          hero_button_copy="Let's Go" 
          featured_dances={FeaturedDancesDummy}
        />
      </div>
      <div className='border-b-2 pt-5'> 
        <IconStrip icons={IconsDummy} />
      </div>
      <div className="py-10 b-2">
        <ContentGrid dances={DancesDummy} />
      </div>
    </div>
  );
};

export default HomePage;