import React from 'react';

const HeroTitle = ({ hero_title }) => {
    return (
        <h1 className="text-[#222222] text-[120px] font-bold">
            {hero_title}
        </h1>
    );
};

export default HeroTitle;