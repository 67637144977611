import React from 'react';
import NavItem from './NavItem';
import SearchBar from './SearchBar';

const Navigation = () => {
  return (
    <div className="flex items-center space-x-4">
      <NavItem nav_item="Guides" path="/guides" />
      <NavItem nav_item="Find A Teacher" path="/find-teacher" />
      <NavItem nav_item="About" path="/about" />
      <SearchBar />
    </div>
  );
};

export default Navigation;