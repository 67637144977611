import React from 'react';
import IconItem from './IconItem';

const IconStrip = ({ icons }) => {
  return (
    <div className="flex justify-center gap-48 space-x-4">
      {icons.map((icon) => (
        <IconItem key={icon.icon_name} {...icon} />
      ))}
    </div>
  );
};

export default IconStrip;
