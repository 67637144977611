import React from 'react';
import FeaturedDanceImage from './FeaturedDanceImage';
import FeaturedDanceTitle from './FeaturedDanceTitle';

const FeaturedDanceCard = ({ featured_src, featured_alt, featured_title }) => {
    return (
        <div className="bg-[#222222] rounded-lg m-4 shadow-md">
            <FeaturedDanceImage featured_src={featured_src} featured_alt={featured_alt} />
            <FeaturedDanceTitle featured_title={featured_title} />
        </div>
    );
};

export default FeaturedDanceCard;