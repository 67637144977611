import React from 'react';

const SearchBar = () => {
  return (
    <input
      type="text"
      placeholder="Love Runs Out step..."
      className="rounded px-3 py-2"
    />
  );
};

export default SearchBar;