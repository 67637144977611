import { Link } from 'react-router-dom';

const NavItem = ({ nav_item, path }) => {
  return (
    <Link to={path} className="text-black text-lg mx-2 hover:underline">
      {nav_item}
    </Link>
  );
};

export default NavItem