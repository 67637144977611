import React from 'react';

const HeroButton = ({ hero_button_copy }) => {
    return (
        <button className="bg-blue-100 hover:bg-green-100 text-[#222222] font-bold py-3 px-6 rounded-lg text-2xl">
            {hero_button_copy}
        </button>
    );
};

export default HeroButton;
