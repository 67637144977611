import React from 'react';
import DanceCard from './DanceCard';

const ContentGrid = ({ dances }) => {
  return (
    <div className="grid grid-cols-5 gap-8 px-20">
      {dances.map((item) => (
        <DanceCard key={item.dance_id} {...item} />
      ))}
    </div>
  );
};

export default ContentGrid;
