import React from 'react';

const HeroDescription = ({ hero_description }) => {
    return (
        <p className="text-[#222222] text-[40px] font-medium mb-8 mt-[-25px] ml-1.5">
            {hero_description}
        </p>
    );
};

export default HeroDescription;