import React from 'react';
import HeroSection from './HeroSection';
import FeaturedDanceSection from './FeaturedDanceSection';

const CoverSection = ({ hero_title, hero_description, hero_button_copy, featured_dances }) => {
    return (
        <div className="relative bg-gradient-to-r from-[#FEF7EC] to-[#CBD8CB] p-8 shadow-lg overflow-hidden">
            <HeroSection 
                hero_title={hero_title} 
                hero_description={hero_description} 
                hero_button_copy={hero_button_copy} 
            />            
            <div className="absolute z-0 top-[50px] left-[850px] h-[400px] w-[400px] border-2 border-[#222222] rounded "> 
                {/* Replace `top-16 left-16` with desired values */}
                <img src="/male_bolly.png" alt="Description" />
            </div>
            <div className="absolute z-0 top-[150px] left-[1200px] h-[350px] w-[350px] border-2 border-[#222222] rounded"> 
                {/* Replace `top-16 left-16` with desired values */}
                <img src="/female_latin.png" alt="Description" />
            </div>
            <div className="absolute z-0 top-[400px] left-[950px] h-[300px] w-[300px] border-2 border-[#222222] rounded"> 
                {/* Replace `top-16 left-16` with desired values */}
                <img src="/duo_party.png" alt="Description" />
            </div>
            <div className="absolute z-0 top-[375px] left-[-50px] h-[300px] w-[400px] border-2 border-[#222222] rounded"> 
                {/* Replace `top-16 left-16` with desired values */}
                <img src="/group_kpop.png" alt="Description" className="w-full h-full object-cover"/>
            </div>           
            <div className="z-10 text-left text-[#222222] text-2xl font-bold px-10 my-4">
                Featured
            </div>
            <FeaturedDanceSection featured_dances={featured_dances} />
        </div>
    );
};

export default CoverSection;