import React from 'react';

const FeaturedDanceTitle = ({ featured_title }) => {
    return (
        <h3 className="text-lg font-bold mt-2 text-center text-white">
            {featured_title}
        </h3>
    );
};

export default FeaturedDanceTitle;