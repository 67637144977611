import React from 'react';
import HeroTitle from './HeroTitle';
import HeroDescription from './HeroDescription';
import HeroButton from './HeroButton';

const HeroSection = ({ hero_title, hero_description, hero_button_copy }) => {
    return (
        <div className="flex-col px-10 pb-96">
            <HeroTitle hero_title={hero_title} />
            <HeroDescription hero_description={hero_description} />
            <HeroButton hero_button_copy={hero_button_copy} />
        </div>
    );
};

export default HeroSection;